import React, { FC, useState } from 'react';
import { useQuery } from 'react-apollo';
import { Divider, Modal } from 'semantic-ui-react';
import classNames from 'classnames';
import moment from 'moment';

import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import FETCH_USER_DETAIL from 'graphql/queries/fetchUserDetail.graphql';

import theme from './theme.scss';

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  phoneNumber: string;
  createdAt: string;
  purse: {
    id: string;
    amount: string;
  };
};

type UserQueryResponse = {
  user: User;
};

type UserQueryVariables = {
  id: string;
};

type UserDetailProps = {
  userId: string;
  onClose: () => void;
};

type UserInformationProps = {
  user: User;
};

const sections = [
  {
    key: 'information',
    name: 'Details',
    emoji: '📋',
  },
];

const UserInformation: FC<UserInformationProps> = ({ user }) => {
  return (
    <div className={theme.information}>
      <div className={theme.field}>
        <p className={theme.label}>FIRST NAME</p>
        <p className={theme.value}>{user.firstName}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>LAST NAME</p>
        <p className={theme.value}>{user.lastName}</p>
      </div>
      <Divider />
      <div className={theme.field}>
        <p className={theme.label}>GENDER</p>
        <p className={theme.value}>{user.gender}</p>
      </div>
      <Divider />
      <div className={theme.field}>
        <p className={theme.label}>JOINED ON</p>
        <p className={theme.value}>{moment(user.createdAt).format('HH:mm a DD MMMM YYYY')}</p>
      </div>
      <Divider />
      <div className={theme.field}>
        <p className={theme.label}>PHONE NUMBER</p>
        <p className={theme.value}>{user.phoneNumber}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>EMAIL</p>
        <p className={theme.value}>{user.email}</p>
      </div>
    </div>
  );
};

const UserDetailModal: FC<UserDetailProps> = ({ userId, onClose }) => {
  const { loading, error, data } = useQuery<UserQueryResponse, UserQueryVariables>(
    FETCH_USER_DETAIL,
    {
      variables: { id: userId },
    }
  );

  const [sectionOnDisplay, setSectionOnDisplay] = useState(sections[0].key);

  function renderContent() {
    if (loading) return <Loader />;

    if (error || !data) return <ErrorDisplay />;

    const user = data.user;

    return (
      <div className={theme.content}>
        <div className={theme.navigation}>
          <p className={theme.modalName}>USER</p>
          {sections.map(section => (
            <div
              className={classNames(
                theme.sectionSelect,
                section.key === sectionOnDisplay ? theme.active : null
              )}
              onClick={() => setSectionOnDisplay(section.key)}
              key={`section-select-${section.key}`}
            >
              <span role="img" aria-label="notepad">
                {section.emoji}
              </span>{' '}
              <span className={theme.sectionName}>{section.name}</span>
            </div>
          ))}
        </div>

        {sectionOnDisplay === 'information' ? <UserInformation user={user} /> : null}
      </div>
    );
  }

  return (
    <Modal open={true} size="small" className={theme.userDetailModal} onClose={onClose}>
      {renderContent()}
    </Modal>
  );
};

export default UserDetailModal;
