import React, { FC, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Button, Message, Select } from 'semantic-ui-react';
import moment from 'moment';

import ModalWithSidePanel from 'components/ModalWithSidePanel';
import GENERATE_REPORT from 'graphql/mutations/generateOrganizationReport.graphql';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import theme from './theme.scss';

type OrganizationReportProps = {
  reportData: {
    organizationId: string;
    organizationName: string;
    fromDate?: Date;
    toDate?: Date;
  };
  onClose: () => void;
};

const typeOptions = [
  { key: 'ax', value: 'EMPLOYEE_TRANSACTION_REPORT', text: 'Employee Transaction Report' },
];

const GenerateOrganizationReport: FC<OrganizationReportProps> = ({ reportData, onClose }) => {
  const [generateReport, { loading, error }] = useMutation(GENERATE_REPORT);

  const [fromDate, setFromDate] = useState<Date>(reportData.fromDate || new Date());

  const [toDate, setToDate] = useState<Date>(reportData.toDate || new Date());

  const [type, setType] = useState<any>('EMPLOYEE_TRANSACTION_REPORT');

  function onSubmit() {
    generateReport({
      variables: {
        organizationId: reportData.organizationId,
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
        reportType: type,
      },
    }).then(({ data }: any) => {
      if (data.generateOrganizationReport && data.generateOrganizationReport.fileLocation)
        window.open(data.generateOrganizationReport.fileLocation);
    });
  }

  return (
    <div className={theme.information}>
      <h3>Generate Organization Report</h3>
      <div className={theme.field}>
        <p className={theme.label}>ORGANIZATION NAME</p>
        <p className={theme.value}>{reportData.organizationName}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>TYPE</p>
        <Select
          placeholder="Select Type"
          value={type}
          onChange={(e, d) => setType(d.value)}
          options={typeOptions}
        />
      </div>
      <div className={theme.field}>
        <p className={theme.label}>FROM DATE</p>
        <DatePicker
          selected={fromDate}
          onChange={value => setFromDate(value)}
          dateFormat="yyyy-MM-dd"
        />
      </div>
      <div className={theme.field}>
        <p className={theme.label}>TO DATE</p>
        <DatePicker
          selected={toDate}
          onChange={value => setToDate(value)}
          dateFormat="yyyy-MM-dd"
        />
      </div>
      {error ? <Message error header="An error occured. Please try again later." /> : null}
      <Button primary loading={loading} onClick={() => onSubmit()}>
        Submit
      </Button>
    </div>
  );
};

const OrganizationReportModal: FC<{
  organizationId: string;
  organizationName: string;
  onClose: () => void;
}> = ({ organizationId, organizationName, onClose }) => {
  const data = { organizationId, organizationName };
  const sections = data
    ? [
        {
          key: 'report',
          name: 'Report',
          emoji: '📋',
          component: <GenerateOrganizationReport reportData={data} onClose={onClose} />,
        },
      ]
    : [];
  return (
    <ModalWithSidePanel
      name="REPORT GENERATION"
      loading={false}
      sections={sections}
      onClose={onClose}
    />
  );
};

export default OrganizationReportModal;
