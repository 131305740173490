import React, { FC } from 'react';

import getRandomGradient from 'utils/getRandomGradient';

import theme from './theme.scss';

type OrganizationGridProps = {
  organizations: {
    id: string;
    name: string;
    image: string;
    __typename: string;
  }[];
  onOrganizationSelect: (organizationId: string) => void;
};

const OrganizationGrid: FC<OrganizationGridProps> = ({ organizations, onOrganizationSelect }) => {
  return (
    <div className={theme.organizationGrid}>
      {organizations.map(organization => (
        <div
          className={theme.organization}
          key={organization.id}
          onClick={() => onOrganizationSelect(organization.id)}
        >
          {organization.image ? (
            <div className={theme.organizationBanner}>
              <div
                className={theme.blurred}
                style={{ backgroundImage: `url(${organization.image})` }}
              />
              <div
                className={theme.clear}
                style={{ backgroundImage: `url(${organization.image})` }}
              />
            </div>
          ) : (
            <div className={theme.organizationBanner}>
              <div className={theme.blurred} style={getRandomGradient()} />
            </div>
          )}
          <div className={theme.label}>
            <p className={theme.name}>{organization.name}</p>
            <p className={theme.type}>{organization.__typename}</p>
          </div>
        </div>
      ))}
      {organizations && organizations.length > 0 ? null : (
        <div className={theme.emptyMessage}>There are no organizations to show.</div>
      )}
    </div>
  );
};

export default OrganizationGrid;
