import React, { FC, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';

import AdminLayout from 'layouts/AdminLayout';
import { Divider } from 'semantic-ui-react';

import ActionPanel from 'components/ActionPanel';
import Title from 'components/Title';
import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import OrganizationGrid from 'components/OrganizationGrid';

import OrganizationReportModal from './OrganizationReportModal';

import FETCH_ORGANIZATION_DASHBOARD from 'graphql/queries/fetchOrganizationDashboard.graphql';

import theme from './theme.scss';

type OrganizationDashboardQueryResponse = {
  organizationDashboard: {
    id: string;
    name: string;
    image: string;
    __typename: string;
  }[];
};

const quickLinks = [
  {
    name: 'Settings',
    route: '/settings',
  },
];

const Dashboard: FC = () => {
  const history = useHistory();
  const [openGenerateReportModal, toggleGenerateReportModal] = useState(false);

  const { loading, error, data } = useQuery<OrganizationDashboardQueryResponse>(
    FETCH_ORGANIZATION_DASHBOARD
  );

  if (loading) return <Loader type="screen" />;

  if (error || !data || !data.organizationDashboard) return <ErrorDisplay type="screen" />;

  const organizations = data.organizationDashboard;

  return (
    <AdminLayout>
      <div className={theme.dashboard}>
        <Title name="Dashboard" />
        <Divider />
        <h2>QUICK LINKS</h2>
        <ActionPanel>
          {quickLinks.map(link => (
            <ActionPanel.Action key={link.route} name={link.name} type="link" route={link.route} />
          ))}
          <ActionPanel.Action
            name={'Generate Bulk Report'}
            type="callback"
            callback={() => toggleGenerateReportModal(true)}
          />
        </ActionPanel>
        <Divider />
        <h2>MY ORGANIZATIONS</h2>
        <OrganizationGrid
          organizations={organizations}
          onOrganizationSelect={id => history.push(`/organizations/${id}`)}
        />
        {openGenerateReportModal ? (
          <OrganizationReportModal
            organizations={organizations}
            onClose={() => toggleGenerateReportModal(false)}
          />
        ) : null}
      </div>
    </AdminLayout>
  );
};

export default Dashboard;
