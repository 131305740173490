import React, { FC, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Button, Checkbox, Message, Select, Table } from 'semantic-ui-react';
import moment from 'moment';

import ModalWithSidePanel from 'components/ModalWithSidePanel';
import GENERATE_REPORT from 'graphql/mutations/generateBulkOrganizationReport.graphql';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import theme from './theme.scss';

type OrganizationReportProps = {
  reportData: {
    organizations: { id: string; name: string; image: string; __typename: string }[];
    fromDate?: Date;
    toDate?: Date;
  };
  onClose: () => void;
};

const typeOptions = [
  { key: 'ax', value: 'EMPLOYEE_TRANSACTION_REPORT', text: 'Employee Transaction Report' },
];

const GenerateOrganizationReport: FC<OrganizationReportProps> = ({ reportData, onClose }) => {
  const [generateReport, { loading, error }] = useMutation(GENERATE_REPORT);
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([]);
  const [formError, setFormError] = useState<string | undefined>();

  const [fromDate, setFromDate] = useState<Date>(reportData.fromDate || new Date());

  const [toDate, setToDate] = useState<Date>(reportData.toDate || new Date());

  const [type, setType] = useState<any>('EMPLOYEE_TRANSACTION_REPORT');

  function onSubmit() {
    setFormError(undefined);

    if (selectedOrganizations.length < 1) {
      setFormError('Select atleast one organization to generate a report');
      return;
    }
    generateReport({
      variables: {
        organizationIds: selectedOrganizations,
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
        reportType: type,
      },
    }).then(({ data }: any) => {
      if (data.generateBulkOrganizationReport && data.generateBulkOrganizationReport.fileLocation)
        window.open(data.generateBulkOrganizationReport.fileLocation);
    });
  }

  return (
    <div className={theme.information}>
      <h3>Generate Organization Report</h3>
      <div className={theme.field}>
        <p className={theme.label}>SELECT ORGANIZATIONS FOR REPORT</p>
        <Table unstackable compact className={theme.itemTable}>
          <Table.Body className={theme.itemBody}>
            {reportData.organizations.map(organization => (
              <Table.Row key={organization.id} className={theme.itemRow}>
                <Table.Cell width="2">
                  <Checkbox
                    checked={selectedOrganizations.includes(organization.id)}
                    onChange={(e, { value }) => {
                      let newOrganizations = [...selectedOrganizations];
                      const itemIndex = selectedOrganizations.findIndex(
                        oId => oId === organization.id
                      );
                      if (itemIndex > -1) {
                        newOrganizations.splice(itemIndex, 1);
                      } else {
                        newOrganizations.push(organization.id);
                      }
                      setSelectedOrganizations(newOrganizations);
                    }}
                  />
                </Table.Cell>
                <Table.Cell width="14">{organization.name}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>TYPE</p>
        <Select
          placeholder="Select Type"
          value={type}
          onChange={(e, d) => setType(d.value)}
          options={typeOptions}
        />
      </div>
      <div className={theme.field}>
        <p className={theme.label}>FROM DATE</p>
        <DatePicker
          selected={fromDate}
          onChange={value => setFromDate(value)}
          dateFormat="yyyy-MM-dd"
        />
      </div>
      <div className={theme.field}>
        <p className={theme.label}>TO DATE</p>
        <DatePicker
          selected={toDate}
          onChange={value => setToDate(value)}
          dateFormat="yyyy-MM-dd"
        />
      </div>
      {error ? <Message error header="An error occured. Please try again later." /> : null}
      {formError ? <Message error header={formError} /> : null}
      <Button primary loading={loading} onClick={() => onSubmit()}>
        Submit
      </Button>
    </div>
  );
};

const OrganizationReportModal: FC<{
  organizations: { id: string; name: string; image: string; __typename: string }[];
  onClose: () => void;
}> = ({ organizations, onClose }) => {
  const data = { organizations };
  const sections = data
    ? [
        {
          key: 'report',
          name: 'Report',
          emoji: '📋',
          component: <GenerateOrganizationReport reportData={data} onClose={onClose} />,
        },
      ]
    : [];
  return (
    <ModalWithSidePanel
      name="REPORT GENERATION"
      loading={false}
      sections={sections}
      onClose={onClose}
    />
  );
};

export default OrganizationReportModal;
